import request from '@/utils/http.js'

export function voyageOrderList(data) {
  return request({
    url: '/harborMng/pageVoyageOrderList',
    method: 'post',
    data
  })
}

export function getVoyageTrajectory(data) {
  return request({
    url: '/harbor/getVoyageTrajectory',
    method: 'post',
    data
  })
}
