<script>
import CalTableHeight from '@/mixins/calTableHeight'
import { voyageOrderList, getVoyageTrajectory } from '@/api/seaFreight'
import { AMapManager } from 'vue-amap'
import { formatDate3 } from '@/utils/time.js'

const amapManager = new AMapManager()

let mapPolyline
let startMarker
let endMarker

export default {
  data() {
    return {
      // Loading
      listLoading: false,
      // 船运列表数据
      dataList: [],
      // 模糊查询请求参数
      queryParams: {
        status: '', //航次状态 0-待出发 1-已出发 2-已到达 3-已完成
        orderNo: '', //航次编号
        shipName: '', //船舶名称
        recycleProjectName: '', //消纳场名称
        currentPage: 1, //当前页
        pageSize: 10 //每页大小
      },
      shipsState: {
        0: { state: '待出发', type: 'warning' },
        1: { state: '已出发', type: 'info' },
        2: { state: '已到达', type: '' },
        3: { state: '已完成', type: 'success' }
      },
      imageType: {
        1: { state: '出港', type: 'info' },
        2: { state: '到港', type: '' },
        3: { state: '卸货', type: 'success' }
      },
      imageSource: {
        1: { state: '手动', type: '' },
        2: { state: '抓拍', type: 'success' }
      },
      deviceUsage: {
        1: { state: '车头（非广角）', type: '' },
        2: { state: '车斗（广角）', type: 'success' }
      },
      total: 0,
      dialogVisible: false,
      imageDetails: [],
      mapDialogVisible: false,
      amapManager,
      events: {
        init: map => {
          this.map = map
        }
      },
      // 地图
      map: null,
      infoType: { 1: 'GPS', 2: 'LBS', 3: 'WIFI', 4: '蓝牙' }
    }
  },
  created() {
    this.queryList()
  },
  mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
  },
  methods: {
    formatDate(value) {
      if (!value) return ''
      return formatDate3(value)
    },
    async queryList() {
      try {
        this.listLoading = true
        const { data } = await voyageOrderList(this.queryParams)
        this.dataList = data.records
        this.total = data.total
      } catch (error) {
        console.log(error)
      } finally {
        this.listLoading = false
      }
    },
    viewDetails(list) {
      if (!list.length) {
        this.$message({
          message: '暂未查询到相关信息',
          type: 'warning'
        })
        return
      }
      this.imageDetails = list
      this.dialogVisible = true
    },
    async viewTracks(voyageOrderNo) {
      try {
        const { data } = await getVoyageTrajectory({ voyageOrderNo })
        if (!data) {
          this.$message({
            message: '暂未查询到相关路径信息',
            type: 'warning'
          })
          return
        }
        this.mapDialogVisible = true
        const path = data.map(item => new AMap.LngLat(item.lon, item.lat))
        setTimeout(() => {
          const polyline = new AMap.Polyline({
            path,
            showDir: true,
            strokeColor: '#28F', //线颜色
            strokeWeight: 6, //线宽
            strokeOpacity: 1, //线透明度
            strokeStyle: 'solid' //线样式
          })
          mapPolyline = polyline
          this.map.add(polyline)
          // 将 markers 添加到地图
          startMarker = this.createMarker(path[0], '起')
          endMarker = this.createMarker(path.at(-1), '终')
          startMarker.setLabel({
            direction: 'right',
            offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
            content: `<div>
              <div>航次编号: ${data[0].voyageOrderNo}</div>
              <div>定位时间: ${this.formatDate(data[0].receiveTime)}</div>
              <div>设备编号: ${data[0].imei}</div>
              <div>消息类型: ${this.infoType[data[0].infoType]}</div>
            </div>`
          })
          this.map.add([startMarker, endMarker])
          this.map.setFitView()
        }, 0)
      } catch (error) {
        console.log(error)
      }
    },
    handleClose(done) {
      this.map.remove(mapPolyline)
      this.map.remove(startMarker)
      this.map.remove(endMarker)
      done()
    },
    createMapIcon(str) {
      return new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: str === '起' ? new AMap.Pixel(-9, -3) : new AMap.Pixel(-95, -3)
      })
    },
    createMarker(position, str) {
      return new AMap.Marker({
        position: position,
        icon: this.createMapIcon(str)
      })
    },
    handleUnloadReceiptImg(url) {
      if (!url) return ['']
      return url.split(',')
    }
  },
  mixins: [CalTableHeight]
}
</script>

<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">船运订单列表</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row type="flex" justify="space-between">
            <el-col :span="8">
              <el-form-item label="航次编号:">
                <el-input
                  v-model.trim="queryParams.orderNo"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="船舶名称:">
                <el-input
                  v-model.trim="queryParams.shipName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消纳场名称:">
                <el-input
                  v-model.trim="queryParams.recycleProjectName"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8">
              <el-form-item label="航次状态:">
                <el-input
                  v-model.trim="queryParams.status"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                  @clear="queryList()"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[10, 50, 100]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList()"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="orderNo" title="航次编号" align="center" width="200" />
            <vxe-table-column field="shipName" title="船舶名称" align="center" width="150" />
            <vxe-table-column field="ton" title="吨位" align="center" width="150" />
            <vxe-table-column field="driverName" title="联系人" align="center" width="150" />
            <vxe-table-column field="contactPhone" title="联系方式" align="center" width="150" />
            <!-- <vxe-table-column field="outRecycleProjectId" title="出发消纳场ID" align="center" width="150" /> -->
            <vxe-table-column field="outPort" title="出发港口" align="center" width="150" />
            <!-- <vxe-table-column field="outReleaserId" title="出港放行人ID" align="center" width="150" /> -->
            <vxe-table-column field="outReleaserTime" title="出港日期" align="center" width="150">
              <template v-slot="{ row }">
                {{ formatDate(row.outReleaserTime) }}
              </template>
            </vxe-table-column>
            <!-- <vxe-table-column field="inRecycleProjectId" title="到达消纳场id" align="center" width="150" /> -->
            <vxe-table-column field="inPort" title="到达港口" align="center" width="150" />
            <!-- <vxe-table-column field="inReleaserId" title="入港放行人ID" align="center" width="150" /> -->
            <vxe-table-column field="inReleaserTime" title="入港日期" align="center" width="150">
              <template v-slot="{ row }">
                {{ formatDate(row.inReleaserTime) }}
              </template>
            </vxe-table-column>
            <vxe-table-column field="unloadReceiptImg" title="卸货票据" align="center" width="150">
              <template v-slot="{ row }">
                <el-image
                  style="width: 50px; height: 25px"
                  :src="handleUnloadReceiptImg(row.unloadReceiptImg)[0]"
                  :preview-src-list="handleUnloadReceiptImg(row.unloadReceiptImg)"
                >
                  <div slot="error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </vxe-table-column>
            <vxe-table-column field="status" title="状态" align="center" width="150">
              <template v-slot="{ row }">
                <el-tag v-if="row.status" size="small" :type="shipsState[row.status].type">{{ shipsState[row.status].state }}</el-tag>
              </template>
            </vxe-table-column>
            <vxe-table-column field="transport" title="累计运输重量(吨)" align="center" width="150" />
            <vxe-table-column field="carSum" title="绑定的汽运单总数" align="center" width="150" />
            <vxe-table-column field="gpsImei" title="gps IMEI编号" align="center" width="150" />
            <vxe-table-column field="outImgList" title="出港抓拍照片" align="center" width="150">
              <template v-slot="{ row }">
                <el-button type="text" size="small" @click="viewDetails(row.outImgList)">查看详情</el-button>
              </template>
            </vxe-table-column>
            <vxe-table-column field="outPhotoList" title="出港手动拍照图片" align="center" width="150">
              <template v-slot="{ row }">
                <el-button type="text" size="small" @click="viewDetails(row.outPhotoList)">查看详情</el-button>
              </template>
            </vxe-table-column>
            <vxe-table-column field="inImgList" title="入港抓拍照片" align="center" width="150">
              <template v-slot="{ row }">
                <el-button type="text" size="small" @click="viewDetails(row.inImgList)">查看详情</el-button>
              </template>
            </vxe-table-column>
            <vxe-table-column field="inPhotoList" title="入港手动拍照图片" align="center" width="150">
              <template v-slot="{ row }">
                <el-button type="text" size="small" @click="viewDetails(row.inPhotoList)">查看详情</el-button>
              </template>
            </vxe-table-column>
            <vxe-table-column field="unloadPhotoList" title="卸货手动拍照图片" align="center" width="150">
              <template v-slot="{ row }">
                <el-button type="text" size="small" @click="viewDetails(row.unloadPhotoList)">查看详情</el-button>
              </template>
            </vxe-table-column>
            <vxe-table-column align="center" fixed="right" title="操作" width="150">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="viewTracks(row.orderNo)">查看轨迹</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="详细信息" :visible.sync="dialogVisible" top="10vh" width="65%">
      <div style="height: 65vh; overflow: auto">
        <el-descriptions style="margin-bottom: 20px" v-for="item in imageDetails" :key="item.id" :column="3" size="small" border>
          <el-descriptions-item>
            <template slot="label">航次编号</template>
            {{ item.voyageOrderNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">照片类型</template>
            <el-tag v-if="item.type" size="small" :type="imageType[item.type].type">{{ imageType[item.type].state }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">照片来源</template>
            <el-tag v-if="item.source" size="small" :type="imageSource[item.source].type">{{ imageSource[item.source].state }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">设备播放序列号</template>
            {{ item.playDeviceSeriNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">播放路数</template>
            {{ item.playChannel }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">设备用途</template>
            <el-tag v-if="item.deviceUsage" size="small" :type="deviceUsage[item.deviceUsage].type">{{ deviceUsage[item.deviceUsage].state }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">照片</template>
            <el-image style="width: 200px; height: 100px" :src="item.imgUrl" :preview-src-list="[item.imgUrl]"> </el-image>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
    <el-dialog title="查看轨迹" :visible.sync="mapDialogVisible" :before-close="handleClose" top="10vh" width="65%">
      <div class="amap-section">
        <el-amap
          :plugin="['AMap.OverView', 'AMap.MapType']"
          :amap-manager="amapManager"
          :zoom="16"
          :events="events"
          :resize-enable="true"
          class="map"
        ></el-amap>
      </div>
    </el-dialog>
  </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 400px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.-column-demo .vxe-header--row .vxe-header--column.-ghost,
.-column-demo .vxe-header--row .vxe-header--column.-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}

.search-box {
  position: absolute !important;
  top: 8px !important;
  left: 24px !important;
  height: 32px !important;
  opacity: 0.8 !important;
}
</style>
